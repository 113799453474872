import React from 'react'
import { Link } from 'react-router-dom'
import Banner1 from '../../macau/assets/img/home/two1.jpg'
import Banner2 from '../../macau/assets/img/home/two2.jpg'

const TwoBannerLayout = () => {
  return (
    <section className='pt-0'>
      <div className="d-flex flex-xs-wrap justify-content-center position-relative align-items-center twoBanner">
        <div className="box_img">
          <div className="picture overflow-hidden">
            <Link to="#">
              <img src={Banner1} className="img-fluid transition" alt="Engagement Rings" />
            </Link>
          </div>
        </div>
        <div className="box_img">
          <div className="picture overflow-hidden">
            <Link to="#">
              <img src={Banner2} className="img-fluid transition" alt="Wedding Rings &amp; Bands" />
            </Link>
          </div>
        </div>
        <div className="banner text-center">
          <div className="bg-banner">
            <h3 className="title_heading">Engagement Rings Collection</h3>
            <p className="mb-0">JUST FOR YOU</p>
            <div className="link mt-5  mt-xl-4 mt-lg-5"> <Link to="/ringsettings" className="button-banner buttonLink">Shop Engagement Rings</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoBannerLayout
