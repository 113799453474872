import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

let blogData = [
  {
    img: require("../../macau/assets/img/blog/blog_1.jpg"),
    date: ["17", "Mar"],
    category: "News",
    title: "Our Favorite Greige Colors Video",
    excerpt:
      ' Where can I get some? The new site provides a closer "look" at our agency – what connects us, what excites us,...',
    link: "#",
    linkText: "Read More",
  },
  {
    img: require("../../macau/assets/img/blog/blog_2.jpg"),
    date: ["17", "Mar"],
    category: "News",
    title: "Best Wedding Flowers",
    excerpt:
      ' Where can I get some? The new site provides a closer "look" at our agency – what connects us, what excites us,...',
    link: "#",
    linkText: "Read More",
  },
  {
    img: require("../../macau/assets/img/blog/blog_3.jpg"),
    date: ["17", "Mar"],
    category: "News",
    title: "More Than Wedding",
    excerpt:
      'Fashion week 2019 Cauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Praesent nonummy mi in odio. Etiam imperdiet imperdiet orci...',
    link: "#",
    linkText: "Read More",
  },
];
const MacauBlog = () => {
  return (
    <section className="py-5">
      <Container className="containerV1">
        <div className="text-center mx-auto mb-5">
          <h3 className="title_heading mb-0 pb-0  d-block text-uppercase  fontMessiri">
            Blogs
          </h3>
          <img
            src={require("../assets/img/line-black.png")}
            alt="Blog Image"
            className="img-fluid"
          />
        </div>

        <Row className="gx-5 gy-5">
          {blogData.map((e, i) => (
            <Col lg={4} md={6} key={i}>
              <div className="content-section-blog-v1 pl-3 pr-3 blogCarousel position-relative">
                <div className="picture position-relative">
                  <Link to="#" className="image_url">
                    <img
                      src={e.img}
                      alt="Our Favorite Greige Colors Video"
                      className="lazyload img-fluid transition"
                    />
                  </Link>
                  <div className="date fontMessiri">
                    <div className="day"> {e.date[0]}</div>
                    <div className="month">{e.date[1]}</div>
                  </div>
                </div>
                <div className="info_blog fontMessiri text-center">
                  <div className="my-3">
                    <Link className="blog_cate colorGrey text-uppercase" to="#">
                      {e.category}
                    </Link>
                  </div>

                  <h4 className="mb-0 title-blog fontMessiri mb-5">
                    <Link to="#" className="text-dark position-relative d-block">
                      {e.title}
                    </Link>
                  </h4>
                  <p className="content mb-0 fontMessiri colorGrey">
                    {e.excerpt}
                  </p>
                  <div className="btn_readmore text-center mt-3">
                    <Link className="fontMessiri buttonLink fs18" to={e.link}>
                      {e.linkText}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default MacauBlog;
