import React from 'react'
import InstagramFeeds from './InstagramFeeds'
import MacauBlog from './MacauBlog'
import MacauCategory from './MacauCategory'
import MacauSlider from './MacauSlider'
import MacauSubscription from './MacauSubscription'
import RingByStyle from './RingStyle'
import ShapeBy from './ShapeBy'
import TestimonialMacau from './TestimonialMacau'
import TwoBannerLayout from './TwoBannerLayout'

const MacauHome = () => {
  return (
    <div>
      <MacauSlider/>
      <MacauCategory/>
      <ShapeBy/>
      <TwoBannerLayout/>
      {/* <RingByStyle/> */}
      <TestimonialMacau/>
      <MacauBlog/>
      {/* <InstagramFeeds/> */}
      <MacauSubscription/>
    </div>
  )
}

export default MacauHome
