import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import swal from 'sweetalert';
import { base_url, currencycode, postHeader } from '../../../Helpers/request';

const MacauSubscription = () => {
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader, setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <div className='bgGrey pt-4 pb-4'>
            <Container fluid className='pt-4'>
                <Row className='justify-content-center'>
                    <Col lg={7} md={10} xs={12}>
                        <div className="">
                            <div className='text-center mx-auto mb-2'>
                                <h3 className="title_heading mb-0 pb-0  d-block text-uppercase fontMessiri mb-2">Sign up to be a Ebenezer Jewelry Repair Atelier LLC

                                    Insider</h3>
                                <p className='colorGrey fontMessiri'>Become a preferred subscriber and get a special offer on your purchase.</p>
                            </div>
                            <div className="rcs_signup_to_input_field">
                                <Row className='justify-content-center'>
                                    <Col lg={8}>
                                        <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    className='maqauInput w-xs-100'
                                                    placeholder="Email Address"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    type="email"
                                                    value={newsletteremail}
                                                    required
                                                    onChange={(e) => setNewsletteremail(e.target.value)}
                                                />
                                                <Button variant="outline-secondary" className='fontMessiri text-uppercase fs15 fullBtnMob' type="submit" id="button-addon2">
                                                    Subscribe
                                                </Button>
                                            </InputGroup>
                                        </Form>
                                    </Col>
                                </Row>


                                <p className='text-center colorGrey fontMessiri'>By signing up you confirm that you have read the Privacy Policy and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing. </p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default MacauSubscription
