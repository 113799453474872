/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/home.css";
import Slider from "react-slick";
import Testi2 from '../../macau/assets/img/testi-2.webp'
import ebeUser from '../../../Assets/images/ebe-user.png'



const TestimonialMacau = () => {
  const GoogleReviewData = [
    {
      name: "Noah Thorn",
      reviewImg: "",
      reviewText: "George and Fabiana were great - very kind, helpful, and quick! They did a great job and we really appreciated their work. I would recommend them and will go back if I need any more jewelry repair or adjustments done.Thanks !",
      reviewDate: "a month ago"
    },
    {
      name: "Tinesha Overton",
      reviewImg: "",
      reviewText: "Very Friendly and kind people. The prices are also affordable. I highly recommend Ebenezer Jewelry Repair.",
      reviewDate: "2 weeks ago"
    },
    {
      name: "Mya Sells",
      reviewImg: "",
      reviewText: "I highly recommend this place!  They are amazing!  My son had 2 silver necklaces that were in need of clasp replacements, so I thought. ",
      reviewDate: "a year ago"
    },
    {
      name: "Suzann Moore",
      reviewImg: "",
      reviewText: "The jewelry repairs and modifications that Eben-Ezer has done for us have been high quality and beautiful.",
      reviewDate: "10 months ago"
    },
    {
      name: "Nadia R",
      reviewImg: "",
      reviewText: "I had a pleasure of working with Mrs. and Mr. Marques at Eben-Ezer Jewelry Repair Atelier. I had a pair of old yellow gold earrings that was turned into white gold by adding a layer of rhodium.",
      reviewDate: "a year ago"
    },
  ]
  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    accessibility: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };


  return (
    //     style = {{ backgroundImage: "url(" + require('../../../themes/macau/assets/img/testimonial_bg.jpg').default + ")" }
    // }
    <section className='py-5 noBgImage ptMob30 pbMob30 macau_testimonila_bg_img'>
      <Container className=''>

        <div className='text-center mx-auto mb-4'>
          <h3 className="title_heading mb-0 pb-0  d-block text-uppercase  fontMessiri">Testimonials</h3>
        </div>

        <Slider {...settings} className='overflow-hidden shapeBySl '>

          {GoogleReviewData.map((val, index) =>
            <Row className='justify-content-center d-flex mb-3 mb-xl-3 mb-lg-4 mb-sm-3 m-auto'>
              <div key={index} className='text-center col-lg-6 col-sm-12 col-md-6'>
                <div className='imgWrapper1'>
                  <img className='img-fluid rounded-circle' src={ebeUser} alt="User Image" />
                </div>
                <h3 className="RingSettingTitleSPL fontMessiri mt-2">{val.name}</h3>
                {/* <p className='colorGrey fontMessiri mb-2'>
                  Marketing Personal
                </p> */}
                <p className='fontMessiri line_clamp_testimonila mb-2'>
                  {val.reviewText}
                </p>
                <a className='text-dark fontMessiri mb-2' href="https://www.google.com/search?q=EBEN-EZER+JEWELRY+REPAIR+ATELIER+LLC&oq=EBEN-EZER+JEWELRY+REPAIR+ATELIER+LLC&aqs=chrome..69i57j69i65j69i60l2.431j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88f50f5c9e194c8b:0xdc5d561fee9fab9b,1,,,,">Read more...</a>
                <p className='fontMessiri mb-0'>{val.reviewDate}</p>
              </div>
            </Row>
          )}


        </Slider>
      </Container>
    </section>
  )
}

export default TestimonialMacau
