import React from 'react'
import { Container } from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useHistory } from 'react-router-dom';

let ShapeData=[
{
    img: require("../../../themes/macau/assets/img/diamond-shape/asscher.svg"),
    title: "Asscher",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/cushion.svg"),
    title: "Cushion",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/emerald.svg"),
    title: "Emerald",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/heart.svg"),
    title: "Heart",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/marquise.svg"),
    title: "Marquise",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/oval.svg"),
    title: "Oval",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/pear.svg"),
    title: "Pear",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/princess.svg"),
    title: "Princess",
    link: "#"
},
{
    img: require("../../../themes/macau/assets/img/diamond-shape/radiant.svg"),
    title: "Radiant",
    link: "#"
}
];

var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    accessibility: true,
    autoplay: true,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 6
        }
      }]
  };





const ShapeBy = () => {
    var history = useHistory();
    const shopbyshape = (value) =>{
        var data1 = {
            shape: value,
        } 
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push('/diamonds');
        window.scrollTo(0, 0);
        }

  return (
    <section className='pt80 pb80 ptMob30 pbMob30 mc_shop_by_shap_section'>
        <Container className=''>
        <div className='text-center mx-auto mb-5'>
        <h3 className="title_heading mb-0 pb-0  d-block text-uppercase  fontMessiri">Shop Diamonds By Shape</h3>
        </div>

        <Slider {...settings} className='overflow-hidden shapeBySl'>
      {ShapeData?.map((val, index) =>
        <li key={index}>
                <button className='noBtn' onClick={()=>shopbyshape(val?.title)} title={val.title} >
                <div className='imgWrapper'>
                <img src={val?.img.default} alt="" />
                </div>
                <h3 className="RingSettingTitleSPL fontMessiri mt-4">{val.title}</h3>
                </button>
            </li>
      )}
        </Slider>

     
        </Container>
    </section>
  )
}

export default ShapeBy
