import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'


let categoryData = [
  {
    img: require('../assets/img/bracelet.jpg'),
    title: "Bracelets",
    link: "/jewelry/bracelets"
  },
  {
    img: require('../assets/img/earrings.jpg'),
    title: "Earrings",
    link: "/jewelry/earrings"
  },
  {
    img: require('../assets/img/pendant.jpg'),
    title: "Pendants",
    link: "/jewelry/pendants"
  }
]
const MacauCategory = () => {
  return (
    <div className='pt80 ptMob30 mc_category_section'>
      <Container className='containerV1'>
        <Row className='gy-4'>
          {
            categoryData.map((e, i) => (
              <Col className='mb-3' lg={4} md={4} key={i}>
                <div className="box_img position-relative overflow-hidden">
                  <div className="picture overflow-hidden">
                    <Link to={e.link}>
                      <img src={e.img} className="img-fluid transition " alt="Bracelets" />
                    </Link>
                  </div>
                  <div className="info">
                    <h3 className="title fontMessiri">{e.title}</h3>
                    <div className="buttonLink"> <NavLink to={e.link} className="button-banner text-dark">Shop now</NavLink>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          }

        </Row>
      </Container>

    </div>
  )
}

export default MacauCategory
