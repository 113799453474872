import React, { useEffect } from 'react';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import "../assets/css/home.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Banner1 from '../../macau/assets/img/sl3.jpg'


let sliderData = [
  {
    img: require('../assets/img/sl3.jpg'),
    url: "/jewelry/wedding-rings",
  },
  {
    img: require('../assets/img/sl4.jpg'),
    url: "/jewelry/fine-jewelry",
  }
]
let sliderDataMobile = [

  {
    img: require('../assets/img/sl3-mob.jpg'),
    url: "/jewelry/wedding-rings",
  },
  {
    img: require('../assets/img/sl4-mob.jpg'),
    url: "/jewelry/fine-jewelry",
  }
]
const MacauSlider = () => {
  const history = useHistory()
  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    accessibility: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>

      <div className='macau _banner_slider_section'>
        <Slider {...settings} className='overflow-hidden bannerSliderHome slider_for_desktop'>
          {sliderData.map((val, index) =>
            <div key={index} className='display_none_slider_in_mobile'>
              <img src={val.img} alt="slider" className='img-fluid' onClick={() => history.push(val.url)} />
            </div>
          )}
        </Slider>
        <Slider {...settings} className='overflow-hidden bannerSliderHome slider_for_mobile'>
          {sliderDataMobile.map((val, index) =>
            <div key={index}>
              <img src={val.img} alt="slider" className='img-fluid' onClick={() => history.push(val.url)} />
            </div>
          )}
        </Slider>
      </div>

    </>
  )
}

export default MacauSlider;